import {
  fileLoadConfig
} from "../actions/configActions";
import { loadConfig } from '../services/config';
import {
  verifyTokenStarted, verifyUserSuccess, verifyTokenEnd,
  userLoginStarted, userLoginFailure, userLogout
} from "../actions/authActions";

export const fileLoadConfigAsync = () => async dispatch => {
  const result = await loadConfig();
  dispatch(fileLoadConfig(result));


}