import React from 'react'
/**  --style="margin-right: 50px;"  */
import logoSuper from '../image/logo-super.png';
import { userLogoutAsync } from "./../asyncActions/authAsyncActions";
import { useDispatch, useSelector } from 'react-redux';

const logoStyle = {
    height: "60px",
    paddingTop: "0.675rem",
    paddingLeft: "1.375rem"

}

const logoGov = {
    paddingLeft: "4.375rem",
    width: "12.5rem"

}

const bienvenidoStyle = {
    paddingRight: "50px",
    marginTop: "-25px",
    paddingLeft: "30%"
}

const contenedorStyle = {
    height: "700px"
}


const cerrarSesionStyle = {
    width: "100px",
    fontSize: "12px",
    marginLeft: "85%",
    marginTop: "-50px"
}




const HeaderGovCo = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(userLogoutAsync());
    }
    const authObj = useSelector(state => state.auth);
    const { nombreCompleto } = authObj;
    return (
        <div>
            <div className="fixed-top ">
                <nav className="navbar navbar-expand-lg barra-superior-govco " aria-label="Barra superior">
                    <a href="https://www.gov.co/" target="_blank" style={logoGov} aria-label="Portal del Estado Colombiano - GOV.CO"></a>
                    <button className="idioma-icon-barra-superior-govco float-right" aria-label="Button to change the language of the page to English"></button>
                </nav>

                <div className="nav-secondary show-transition" id="nav-secondary">
                    <div className="container-govco">
                        <div className="float-left">
                            <a className="navbar-brand" target="_blank" href="https://www.supersociedades.gov.co/">
                                <img src={logoSuper} style={logoStyle} alt="Logo Gov.co" />
                            </a>
                        </div>

                        {
                            nombreCompleto != undefined &&
                            <div className="navbar-collapse  navbar-first-menu float-right" style={cerrarSesionStyle}>
                                <div className="nav-item-primary ml-auto mr-2 is-scroll">
                                    <div >
                                        <span className="header-text-opciones font-weight-light d-block btn-boder-white" onClick={handleLogout}  >Cerrar
                                            sesión</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            nombreCompleto != undefined &&



                            <div className="float-right text-center" style={bienvenidoStyle} id="nombreUsuario" >
                                Bienvenido: {nombreCompleto}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
        </div>
    )
};

export default HeaderGovCo