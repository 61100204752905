import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import "../govco/src/form/accordion.css";
import "../govco/src/form/tables.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from 'react-paginate';
import {
  obtenerParametrosPorCodigo,
  actualizarTramitePermitidoService,
  agregarTramitePermitidoService,
} from "../services/consulta";
import { useHistory } from "react-router-dom";
import "../govco/src/form/button_app.css";

const TablaTramites = ({ datos }) => {
  const buttonStyle = {
    width: "110px",
    height: "42px",
    backGroundColor: "#EBF5FB !important",
  };

  const authObj = useSelector((state) => state.auth);
  const { baseUrl } = authObj;
  const [disable, setDisable] = useState(true);
  const [index, setIndex] = useState(null);
  const [textoGuardar, setTextoGuardar] = useState("Texto Guardar");
  const showPopupTramites = useFormInput(false);
  const tituloTramite = useFormInput("");
  const codigo = useFormInput("");
  const idTramite = useFormInput("");
  const tramite = useFormInput("");
  const descripcion = useFormInput("");
  const tamanioMaxDoc = useFormInput("");
  const selEstadoTramite = useFormInput("");
  const selEstadoActivo = useFormInput("");
  const optionsEstadoTramites = useFormInput([]);
  const optionsEstadoInactivo = useFormInput([]);
  const mensaje = useFormInput(false);
  const showError = useFormInput(false);
  const history = useHistory();
  const showSucess = useFormInput(false);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const handleCloseMessage = () => {
    showSucess.onChange({ target: { value: false } });
  };

  const handlednBlur = (valor) => {
    if (valor.value == "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const handleCloseTramitePopPup = () => {
    showPopupTramites.onChange({ target: { value: false } });
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const buttonStyleNuevo = {
    width: "110px",
    height: "42px",
    background: "green",
    border: "none",
  };

  const handleOpenPopupTramites = (dato, index) => {
    setIndex(index);
    setTextoGuardar("Editar Tramite");
    showPopupTramites.onChange({ target: { value: true } });
    tituloTramite.onChange({ target: { value: dato.titulo } });
    codigo.onChange({ target: { value: dato.codigo } });
    selEstadoTramite.onChange({ target: { value: dato.estado } });
    idTramite.onChange({ target: { value: dato.id } });
    tramite.onChange({ target: { value: dato.tramite } });
    descripcion.onChange({ target: { value: dato.descripcion } });
    selEstadoActivo.onChange({
      target: { value: dato.activo == "1" ? "ACTIVO" : "INACTIVO" },
    });
    tamanioMaxDoc.onChange({ target: { value: dato.tamanioMax } });
    setDisable(false);
  };

  const handleAddOpenPopupTramites = () => {
    setIndex(null);
    setTextoGuardar("Nuevo Tramite");
    showPopupTramites.onChange({ target: { value: true } });
    tituloTramite.onChange({ target: { value: null } });
    codigo.onChange({ target: { value: null } });
    selEstadoTramite.onChange({ target: { value: "" } });
    idTramite.onChange({ target: { value: null } });
    tramite.onChange({ target: { value: null } });
    descripcion.onChange({ target: { value: null } });
    selEstadoActivo.onChange({ target: { value: "" } });
    tamanioMaxDoc.onChange({ target: { value: null } });
    setDisable(false);
  };

  const obtenerEsatdosTramites = useCallback(async (event) => {
    const result = await obtenerParametrosPorCodigo("ESTADOS", baseUrl);
    if (result.response) {
      mensaje.onChange({
        target: {
          value:
            "Ha ocurrido un error en el servicio, por favor intenta mas tarde.",
        },
      });
      showError.onChange({ target: { value: true } });
    } else {
      optionsEstadoTramites.onChange({ target: { value: result.data.objeto } });
    }
  }, []);

  const obtenerEsatdosInactivo = useCallback(async (event) => {
    const result = await obtenerParametrosPorCodigo("ESTAACT", baseUrl);
    if (result.response) {
      mensaje.onChange({
        target: {
          value:
            "Ha ocurrido un error en el servicio, por favor intenta mas tarde.",
        },
      });
      showError.onChange({ target: { value: true } });
    } else {
      optionsEstadoInactivo.onChange({ target: { value: result.data.objeto } });
    }
  }, []);

  const handleGuardarTramite = async (e) => {
    let obj = {
      id: idTramite.value,
      titulo: tituloTramite.value,
      codigo: codigo.value,
      estado: selEstadoTramite.value,
      tramite: tramite.value,
      descripcion: descripcion.value,
      activo: selEstadoActivo.value == "1" ? true : false,
      tamanioMax: tamanioMaxDoc.value,
    };
    let result = null;
    if (obj.id == null) {
      result = await agregarTramitePermitidoService(baseUrl, obj);
      if (result.response) {
        mensaje.onChange({
          target: {
            value:
              "Ha ocurrido un error en el servicio, por favor intenta mas tarde.",
          },
        });
        showError.onChange({ target: { value: true } });
      } else {
        const { id } = result.data.objeto;
        obj.id = id;
        datos.push(obj);
        showSucess.onChange({ target: { value: true } });
      }
    } else {
      result = await actualizarTramitePermitidoService(baseUrl, obj);
      datos[index].titulo = tituloTramite.value;
      datos[index].id = idTramite.value;
      datos[index].codigo = codigo.value;
      datos[index].estado = selEstadoTramite.value;
      datos[index].tramite = tramite.value;
      datos[index].descripcion = descripcion.value;
      datos[index].activo = selEstadoActivo.value == "1" ? true : false;
      datos[index].tamanioMax = tamanioMaxDoc.value;
      mensaje.onChange({ target: { value: result.data.objeto } });
      showSucess.onChange({ target: { value: true } });
    }
    showPopupTramites.onChange({ target: { value: false } });
  };

  const seleccionEstadoTramite = (selectedoption) => {
    if (selectedoption) {
      selEstadoTramite.onChange({ target: { value: selectedoption.valor } });
    }
  };

  const seleccionEstadoActivo = (selectedoption) => {
    if (selectedoption) {
      selEstadoActivo.onChange({ target: { value: selectedoption.valor } });
    }
  };

  useEffect(() => {
    obtenerEsatdosTramites();
    obtenerEsatdosInactivo();
  }, []);

  return (
    <div>
      <div class="table-simple-headblue-govco">
        <div className="row">
          <div className="col-lg-3">
            <h4 class="modal-title-tables" id="tableDescCursorRows">
              Documentos Permitidos
            </h4>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              className="btn-govco fill-btn-govco"
              icon-position="left"
              onClick={() => handleAddOpenPopupTramites()}
            >
              Adicionar
            </button>
          </div>
        </div>
        <br></br>
        <table
          class="table display table-responsive-sm table-responsive-md"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Acciones</th>
              <th>Titulo</th>
              <th>Codigo</th>
              <th>Estado</th>
              <th>Tramite</th>
              <th>Descripcion</th>
              <th>Activo</th>
              <th>TamañoMaxDoc</th>
            </tr>
          </thead>
          <tbody>
            {datos.slice(pagesVisited, pagesVisited + itemsPerPage).map((dato, index) => (
              <tr key={index} class="hoverSelect">
                <td>
                  <div class="checkbox-govco">
                    <button
                      type="button"
                      className="btn btn-primary btn-modal-govco btn-contorno"
                      icon-position="left"
                      onClick={() => handleOpenPopupTramites(dato, index)}
                    >
                      Editar
                    </button>
                  </div>
                </td>
                <td>{dato.titulo}</td>
                <td>{dato.codigo}</td>
                <td>{dato.estado}</td>
                <td>{dato.tramite}</td>
                <td>{dato.descripcion}</td>
                <td>{dato.activo ? "ACTIVO" : "NO ACTIVO"}</td>
                <td>{dato.tamanioMax}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Siguiente'}
          pageCount={Math.ceil(datos.length / itemsPerPage)}
          onPageChange={changePage}
          containerClassName={'pagination pagination-container-bootstrap'}
          activeClassName={'active'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
        />
      </div>
      <Modal show={showPopupTramites.value} onHide={handleCloseTramitePopPup}>
        <Modal.Body>
          <div className="modal-body modal-body-govco center-elements-govco">
            <h3>{textoGuardar}</h3>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="tituloTramite">
                  <b className="text-danger">*</b> T&iacute;tulo :
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control input-govco"
                  placeholder="T&iacute;tulo"
                  id="tituloTramite"
                  name="tituloTramite"
                  minlength="5"
                  maxlength="50"
                  trim="true"
                  required="true"
                  {...tituloTramite}
                  onBlur={() => handlednBlur(tituloTramite)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="codigo">
                  <b className="text-danger">*</b> Codigo :
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control input-govco"
                  placeholder="Codigo"
                  id="codigo"
                  name="codigo"
                  minlength="5"
                  maxlength="50"
                  trim="true"
                  required="true"
                  {...codigo}
                  onBlur={() => handlednBlur(codigo)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="estado">
                  <b className="text-danger">*</b> Estado :
                </label>
              </div>
              <div className="col-lg-6">
                <Select
                  className=" basic-single"
                  placeholder="Seleccione un estado"
                  classNamePrefix="select"
                  getOptionValue={(option) => option["valor"]}
                  getOptionLabel={(option) => option["nombre"]}
                  options={optionsEstadoTramites.value}
                  onChange={seleccionEstadoTramite}
                  defaultInputValue={selEstadoTramite.value}
                  onBlur={() => handlednBlur(selEstadoTramite.value)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="tramite">
                  <b className="text-danger">*</b> Tramite :
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control input-govco"
                  placeholder="Tramite"
                  id="tramite"
                  name="tramite"
                  minlength="5"
                  maxlength="50"
                  trim="true"
                  required="true"
                  {...tramite}
                  onBlur={() => handlednBlur(tramite)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="descripcion">
                  <b className="text-danger">*</b> Descripci&oacute;n :
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control input-govco"
                  placeholder="Descripci&oacute;n"
                  id="descripcion"
                  name="descripcion"
                  minlength="5"
                  maxlength="50"
                  trim="true"
                  required="true"
                  {...descripcion}
                  onBlur={() => handlednBlur(descripcion)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="activo">
                  <b className="text-danger">*</b> Activo :
                </label>
              </div>
              <div className="col-lg-6">
                <Select
                  className=" basic-single"
                  placeholder="Seleccione un estado"
                  classNamePrefix="select"
                  getOptionValue={(option) => option["valor"]}
                  getOptionLabel={(option) => option["nombre"]}
                  options={optionsEstadoInactivo.value}
                  onChange={seleccionEstadoActivo}
                  defaultInputValue={selEstadoActivo.value}
                  onBlur={() => handlednBlur(selEstadoActivo.value)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <label for="activo">
                  <b className="text-danger">*</b> Tama&ntilde;o Documento :
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control input-govco"
                  placeholder="Tama&ntilde;o M&aacute;ximo"
                  id="tamanioMaxDoc"
                  name="tamanioMaxDoc"
                  minlength="5"
                  maxlength="50"
                  trim="true"
                  required="true"
                  {...tamanioMaxDoc}
                  onBlur={() => handlednBlur(tamanioMaxDoc)}
                />
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-lg-6">
                <Button
                  variant="secondary"
                  className="btn btn-primary btn-modal-govco"
                  onClick={handleGuardarTramite}
                  disabled={disable}
                >
                  Guardar
                </Button>
              </div>

              <div className="col-lg-6">
                <Button
                  variant="secondary"
                  className="btn btn-primary btn-modal-govco"
                  onClick={handleCloseTramitePopPup}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSucess.value} onHide={handleCloseMessage}>
        <Modal.Body>
          <div className="modal-body modal-body-govco center-elements-govco">
            <div className="modal-icon">
              <span className="modal-icon-govco modal-success-icon"></span>
            </div>
            <h3 className="modal-title-govco success-govco">
              Proceso ejecutado de forma correcta
            </h3>
            <p className="modal-text-govco modal-text-center-govco">
              {mensaje.value}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="modal-footer-govco modal-footer-alerts-govco d-flex justify-content-center"
            onClick={handleCloseMessage}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// custom hook to manage the form input
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

const changePage = ({ selected }) => {
  setPageNumber(selected);
};

export default TablaTramites;
