import {
  VERIFY_USER_SUCCESS,
  USER_LOGIN_STARTED, USER_LOGIN_FAILURE, USER_LOGOUT, LOAD_FILE_CONFIG
} from "./actionTypes";
import { setAuthToken } from "../services/email";


// user login - start
export const userLoginStarted = () => {
  return {
    type: USER_LOGIN_STARTED
  }
}

// user login - failure
export const userLoginFailure = (error = 'Something went wrong. Please try again later.') => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: {
      error
    }
  }
}

// verify token - success
export const verifyUserSuccess = ({ access_token, expires_in, nombreCompleto, userName }) => {
  return {
    type: VERIFY_USER_SUCCESS,
    payload: {
      access_token,
      expires_in,
      nombreCompleto,
      userName: userName
    }
  }
}

// handle user logout
export const userLogout = () => {
  sessionStorage.clear();
  setAuthToken();
  return {
    type: USER_LOGOUT
  }
}