import React from 'react'
import logoGovCo from '../image/logo_colombia.png';
import logoGovCoBlanco from '../image/logo_govco_blanco.png';
const FooterGovCo = () => {
    return (
        <div>

            <div className="govco-footer mt-5">
                <div className="row govco-portales-contenedor m-0">
                    <div className="col-4 govco-footer-logo-portal">
                        <div className="govco-logo-container-portal">
                            <a href="https://www.gov.co/home/" target="_blank" title="Enlace a Gov.co">
                                <img src={logoGovCoBlanco} alt="Logo Gov.co" width="150px" className="img-responsive" />
                            </a>
                            <span className="govco-logo">
                            </span>

                            <span className="govco-separator"></span>
                            <span className="govco-co"></span>
                            <a href="https://www.colombia.co/" target="_blank" title="Enlace a Marca País Colombia">
                                <img src={logoGovCo} alt="Logo Marca País Colombia" width="60px" className="img-responsive" />
                            </a>
                        </div>
                    </div>
                    <div className="col-4 govco-info-datos-portal">
                        <div className="govco-separator-rows"></div>
                        <div className="govco-texto-datos-portal">
                            <p className="govco-text-header-portal-1">
                                Superintendencia de Sociedades
                            </p>
                            Dirección: Avenida El Dorado No. 51-80 / Bogotá - Colombia. <br />

                            Horario de atención al público: Lunes a Viernes de 8:00am a 5:00pm.
                        </div>
                        <div className="govco-network extramt-network">
                            <div className="govco-iconContainer">
                                <span className="icon-portal govco-twitter-square"></span>
                                <span className="govco-link-portal"><a className="text-decoration-none text-light" target="_blank" href="https://twitter.com/SSociedades" title="Twitter">
                                    @Supersociedades
                                </a></span>
                            </div>
                            <div className="govco-iconContainer">
                                <span className="icon-portal govco-instagram-square"></span>
                                <span className="govco-link-portal">
                                    <a className="text-decoration-none text-light" target="_blank" href="https://www.instagram.com/ssociedades/" title="Instagram">
                                        @Supersociedades
                                    </a>
                                </span>
                            </div>
                            <div className="govco-iconContainer">
                                <span className="icon-portal govco-facebook-square"></span>
                                <a className="text-decoration-none text-light" target="_blank" href="https://www.facebook.com/SSociedades/" title="Facebook">
                                    @Supersociedades
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 govco-info-telefonos">
                        <div className="govco-separator-rows"></div>
                        <div className="govco-texto-telefonos">
                            <p className="govco-text-header-portal-1">
                                <span className="govco-phone-alt"></span>
                                Contacto
                            </p>

                            Teléfono conmutador: <br />
                            +57(1) 220 1000<br />
                            Línea gratuita:01-800-0114319 <br />

                            Correo institucional: <br />
                            webmaster@supersociedades.gov.co
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
};

export default FooterGovCo