import React from 'react'



function smoothscroll(event) {
    let windowScrolled = false;
    window.addEventListener('scroll', function () {
        var objBackTop = document.querySelector('.scroll-to-top');
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 100) {
            windowScrolled = true;
            objBackTop.classList.add('show-scrollTop');
        } else if (((this.windowScrolled && window.pageYOffset) || document.documentElement.scrollTop || document.body.scrollTop) < 10) {
            objBackTop.classList.remove('show-scrollTop');
        }
    });

    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
        //window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll );
        if (currentScroll < 20) {
            window.scrollTo(0, 0);
            document.documentElement.scrollTop = 0;
            windowScrolled = false;
        }
        //event.preventDefault();
    }
}


function reactVolverArriba(event) {
    smoothscroll(event);
}

function SubirArchivoGovCo() {
    return (
        <div className="scroll-to-top show-scrollTop">
            <button className="volver-arriba-govco ml-5" onClick={reactVolverArriba} aria-describedby="descripcionId"
                aria-label="Botón volver arriba">
            </button>
            <span className="d-none" id="descripcionId"> Seleccione esta opción como atajo para volver al inicio de esta página.
            </span>
        </div>
    )
};

export default SubirArchivoGovCo