import React from 'react'

function BarraAccesibilidadGovCo() {
    const [mood, setMood] = React.useState(1);

    function reactCambiarContexto() {
        cambiarContraste();
    }

    function reactDisminuirTamanio() {
        zoomOut();
    }
    function reactAumentarTamanio() {
        zoomIn();
    }


    function cambiarContraste() {
        if (mood == 1) {
            document.body.classList.add('all');
            setMood(mood + 1);


        } else {
            document.body.classList.remove('all');
            setMood(mood - 1);
        }
    }

    function zoomOut() {
        var size = parseInt(getComputedStyle(document.documentElement).fontSize);
        if (size > 13) {
            size--;
            var font = size.toString();
            document.querySelector('html').style.fontSize = font + 'px';
        }
    }

    function zoomIn() {
        var size = parseInt(getComputedStyle(document.documentElement).fontSize);
        if (size < 19) {
            size++;
            var font = size.toString();
            document.querySelector('html').style.fontSize = font + 'px';
        }
    }


    return (
        <div className="content-example-barra" >
            <div className="barra-accesibilidad-govco" >
                <button id="botoncontraste" className="icon-contraste" onClick={reactCambiarContexto} >
                    <span id="titlecontraste" > Contraste </span>
                </button>
                < button id="botondisminuir" className="icon-reducir" onClick={reactDisminuirTamanio} >
                    <span id="titledisminuir" > Reducir letra </span>
                </button>
                < button id="botonaumentar" className="icon-aumentar" onClick={reactAumentarTamanio} >
                    <span id="titleaumentar" > Aumentar letra </span>
                </button>
            </div>
        </div>
    )
};

export default BarraAccesibilidadGovCo