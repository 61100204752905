import React, { useEffect, useState, Component, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from "../actions/authActions";
import { setAuthToken } from '../services/consulta';
import { sendEmail } from '../services/email';
import { fileLoadConfigAsync } from '../asyncActions/configAsyncActions';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router-dom';

//govco
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Alert, Row } from 'react-bootstrap';
import ContentEditable from 'react-contenteditable'
//Hojas de estilos
import "../govco/src/form/carga-de-archivo.css"
import "../govco/src/transversal/barra-superior.css"
import "../govco/src/transversal/pie-de-pagina.css"
import "../govco/src/form/entradas-de-texto.css";
import "../govco/src/general/botones.css"
import "../govco/src/general/alerta-modal.css";
import "../govco/src/general/tablas.css"
import "../govco/src/general/indicador-de-carga.css"
import "../govco/src/transversal/barra-accesibilidad.css";
import "../govco/src/general/etiqueta.css"
import "../govco/src/form/desplegable.css"
//Librerias
import * as entrada from "../govco/src/form/entradas-de-texto.js"
import * as ip from "../lib/GetIP.js"
//Componentes
import Modal from 'react-bootstrap/Modal';
import TablaArchivo from '../components/tablaArchivo';
import moment from 'moment';
import { obtenerTiposIdentificacionService } from '../services/consulta';
import { listarTiposPersonaService } from '../services/consulta';
import { obtenerPaisesNacionalidadService } from '../services/consulta';
import { obtenerDepartamentosService } from '../services/consulta';
import { obtenerCiudadesService } from '../services/consulta';
import { procesarCertificadoSociedadesService } from '../services/consulta';
import { listarPreguntasPQRSPorTipoService } from '../services/consulta';




function CertificadoCamaras(){
  const history = useHistory();
  const dispatch = useDispatch();
  const authObj = useSelector(state => state.auth);
  const { access_token, expires_in, userName, baseUrl } = authObj;
  const showError = useFormInput(false);
  const showDireccion = useFormInput(false);
  const mensaje = useFormInput(false);
  const showSucess = useFormInput(false);
  const showSpinner = useFormInput(false);
  //campos del formulario
  const identificacionCorresponsal = useFormInput('');
  const nombreCorresponsal = useFormInput('');
  const primerNombreCorresponsal = useFormInput('');
  const segundoNombreCorresponsal = useFormInput('');
  const primerApellidoCorresponsal = useFormInput('');
  const segundoApellidoCorresponsal = useFormInput('');
  const direccionCorresponsal = useFormInput('');
  const telefonoCorresponsal = useFormInput('');
  const movilCorresponsal = useFormInput('');
  const emailCorresponsal = useFormInput('');
  const certificadoDirigidoA = useFormInput('QUIEN INTERESE');
  const identificacionAplicaA = useFormInput('');
  const pqrsAutorizo = useFormInput('0');

  //const segundoApellidoCorresponsal = useFormInput('');

  const tipoPersona = useFormInput('1');
  const idPais = useFormInput('80');
  const idDepartamento = useFormInput('11'); //8 Atlantico 11 Cundinamarca Bogota
  const tipoPregunta = useFormInput('1'); // 1) Grupo de interes
  const tipoPregunta2 = useFormInput('2'); // 2) Genero
  const tipoPregunta3 = useFormInput('3'); // 3) Grupos poblaciones

  const optionstipoIdentificacionCorresponsal = useFormInput([]);
  const tipoIdentificacionCorresponsal = useFormInput('');
  const optionsCodigoTipoPersonaCorresponsal = useFormInput([]);
  const codigoTipoPersonaCorresponsal = useFormInput('');
  const optionscodigoPais = useFormInput([]);
  const codigoPais = useFormInput('');
  const optionsDepartamentos = useFormInput([]);
  const departamentos = useFormInput('');
  const optionsCiudades = useFormInput([]);
  const ciudades = useFormInput('');
  const optionsListarPreguntasPQRS = useFormInput([]);
  const optionsListarPreguntasPQRS2 = useFormInput([]);
  const optionsListarPreguntasPQRS3 = useFormInput([]);
  const preguntasPRQS = useFormInput('');
  const preguntasPRQS2 = useFormInput('');
  const preguntasPRQS3 = useFormInput('');


  //select TipoIdentificacion
  const obtenerTiposIdent = useCallback (async (event) => {
    
    const result = await obtenerTiposIdentificacionService(baseUrl);
   // alert(JSON.stringify(result.data.objeto));
    if (result.response) {
      mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
      showError.onChange({ target: { value: true } });
     } else {
          optionstipoIdentificacionCorresponsal.onChange({ target: { value: result.data.objeto }})
     }
  },[]);


  function volver() {
    history.goBack();
  }

  //Select obtenerTiposPersona
  const obtenerTiposPersona = useCallback (async (tipoPersona) => {

      const resultTipoPersona = await listarTiposPersonaService(tipoPersona, baseUrl);

      if (resultTipoPersona.response) {
        mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
        showError.onChange({ target: { value: true } });
      } else {
              optionsCodigoTipoPersonaCorresponsal.onChange({ target: { value: resultTipoPersona.data.objeto }})              
            }
    
      },[]);

      //Select obtenerPaisesNacionalidad
      const obtenerPaisesNacionalidad = useCallback (async (event) => {

      const resultObtenerPaises = await obtenerPaisesNacionalidadService(baseUrl);
    
      if (resultObtenerPaises.response) {
        mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
        showError.onChange({ target: { value: true } });
       } else {
        optionscodigoPais.onChange({ target: {
          value: "80", label: "Colombia",
          value: resultObtenerPaises.data.objeto }})              
       }
      },[]);

      //Select obtenerDepartamentos
      const obtenerDepartamentos = useCallback (async (idPais) => {

      const respuestaDepartamentos = await obtenerDepartamentosService(idPais, baseUrl);

      if (respuestaDepartamentos.response) {
        mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
        showError.onChange({ target: { value: true } });
       } else {
        optionsDepartamentos.onChange({ target: { value: respuestaDepartamentos.data.objeto }})  
       }
      },[]);

      
      //Select obtenerCiudades
      const obtenerCiudades = useCallback (async (idDepartamento) => {
        
        const respuestaCiudades = await obtenerCiudadesService(idPais.value, idDepartamento, baseUrl);

      if (respuestaCiudades.response) {
        mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
        showError.onChange({ target: { value: true } });
       } else {
         optionsCiudades.onChange({ target: { value: respuestaCiudades.data.objeto }})  
       }

      },[]);

       //Select listarPreguntasPQRS
       const listarPreguntasPQRS = useCallback (async (event) => {

        const respuestaTipoPreguntas = await listarPreguntasPQRSPorTipoService(tipoPregunta.value, baseUrl);
  
        if (respuestaTipoPreguntas.response) {
          mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
          showError.onChange({ target: { value: true } });
         } else {
          optionsListarPreguntasPQRS.onChange({ target: { value: respuestaTipoPreguntas.data.objeto }})  
         }
        },[]);
  
          //Select listarPreguntasPQRS2
          const listarPreguntasPQRS2 = useCallback (async (event) => {
  
            const respuestaTipoPreguntas2 = await listarPreguntasPQRSPorTipoService(tipoPregunta2.value, baseUrl);
      
            if (respuestaTipoPreguntas2.response) {
              mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
              showError.onChange({ target: { value: true } });
             } else {
              optionsListarPreguntasPQRS2.onChange({ target: { value: respuestaTipoPreguntas2.data.objeto }})  
             }
            },[]);
                    //Select listarPreguntasPQRS3
          const listarPreguntasPQRS3 = useCallback (async (event) => {
  
            const respuestaTipoPreguntas3 = await listarPreguntasPQRSPorTipoService(tipoPregunta3.value, baseUrl);
      
            if (respuestaTipoPreguntas3.response) {
              mensaje.onChange({ target: { value: "Ha ocurrido un error en el servicio, por favor intenta mas tarde." } })
              showError.onChange({ target: { value: true } });
             } else {
              optionsListarPreguntasPQRS3.onChange({ target: { value: respuestaTipoPreguntas3.data.objeto }})  
             }
            },[]);


            const [isDisabledRazonSocial, setIsDisabled] = useState(false);
            const [isDisabledNombres, setIsDisabledNombres] = useState(false);
      
            const validarNombreRazonSocial = useCallback (async (tipoPersona) => {
      
                //alert(JSON.stringify(tipoPersona));
                //1 Persona natural
                if (tipoPersona == "1") {
                  setIsDisabled(!isDisabledRazonSocial)
                }else{
                  setIsDisabled(isDisabledRazonSocial)
                }
                 //5 Persona juridica 
                if (tipoPersona == "5") {
                  setIsDisabledNombres(!isDisabledNombres)
                }else{
                  setIsDisabledNombres(isDisabledNombres)
                }
      
                },[]);


  useEffect(() => {

    //llamado de cada Select
    obtenerTiposIdent();
   // obtenerTiposPersona();
    obtenerPaisesNacionalidad();
   // obtenerDepartamentos();
   // obtenerCiudades();
    listarPreguntasPQRS();
    listarPreguntasPQRS2();
    listarPreguntasPQRS3();
    validarBotonEnviar();

    entrada.initInput();
    ip.getIPs();
    dispatch(fileLoadConfigAsync());
    const verifyTokenTimer = setTimeout(() => {
      if (sessionStorage.getItem('ip') != null) {        
        clearTimeout(verifyTokenTimer);
        setAuthToken(access_token, userName, sessionStorage.getItem('ip'));
      }

    }, 1000);
    return () => {
      clearTimeout(verifyTokenTimer);
    }
  }, []);


  const buttonStyle = {
    width: "110px",
    height: "42px"
  }

  const spinnerStyle = {
    width: "90px",
    height: "90px",
    borderWidth: "8px"
  }

  const modalStyle = {
    position: "absolute",
    top: "40%",
    left: "10%",
    marginTop: "20%",
    marginLeft: "-50px"
  }
   
  const panelheading = {
    padding: "15px",
    borderbottom: "1px solid transparent",
    bordertopleftradius: "3px",
    borderttoprightradius: "3px"
  }
  


    //Combo de tipoIdentificacionCorresponsal
    const seleccionoTipoIdentificacionCorresponsal = (selectedoption) => {
        if (selectedoption) {
            tipoIdentificacionCorresponsal.onChange({ target: { value: selectedoption.id}});
            obtenerTiposPersona( selectedoption.id );
        }
    };

    //Combo de codigoTipoPersonaCorresponsal
    const seleccionoCodigoTipoPersonaCorresponsal = (selectedoption) => {
      if (selectedoption) {
        codigoTipoPersonaCorresponsal.onChange({ target: { value: selectedoption.tipoIdentificacion}});
         validarNombreRazonSocial(selectedoption.tipoIdentificacion);
      }
      };

    //Combo de codigoPais
    const seleccionocodigoPais = (selectedoption) => {
      if (selectedoption) {
        codigoPais.onChange({ target: { value: selectedoption.idPais}});
          obtenerDepartamentos(selectedoption.idPais);
      }
      };

    //Combo de despartamentos
    const seleccionoDepartamentos = (selectedoption) => {
      if (selectedoption) {
        departamentos.onChange({ target: { value: selectedoption.idDepartamento}});
          console.log(`selected: ${selectedoption.nombre}`);
          obtenerCiudades(selectedoption.idDepartamento);
      }
      };

    //Combo de ciudades
    const seleccionoCiudades = (selectedoption) => {
      if (selectedoption) {
        ciudades.onChange({ target: { value: selectedoption.idCiudad}});          
      }
      };
      
       //Combo de ListarPregunstasPQRS
    const seleccionoPreguntasPQRS = (selectedoption) => {
      if (selectedoption) {
        preguntasPRQS.onChange({ target: { value: selectedoption.codigo}});
         
      }
      };
         //Combo de ListarPregunstasPQRS2
    const seleccionoPreguntasPQRS2= (selectedoption) => {
      if (selectedoption) {
        preguntasPRQS2.onChange({ target: { value: selectedoption.codigo}});
          
      }
      };
         //Combo de ListarPregunstasPQRS3
         const seleccionoPreguntasPQRS3= (selectedoption) => {
          if (selectedoption) {
            preguntasPRQS3.onChange({ target: { value: selectedoption.codigo}});
              
          }
          };


    const errorClassIdentificacion = () => {
        return (identificacionCorresponsal.value.length > 9 ? 'text-danger' : 'ocultarCampo');
    }

    const handleCloseError = () => { showError.onChange({ target: { value: false } }); };

    const handleOpenDireccion = () => {
      showDireccion.onChange({ target: { value: true } });
    };
  
    const handleCloseDireccion = () => { showDireccion.onChange({ target: { value: false } }); };

    const [isDisabledEnviar, setIsDisabledEnviar] = useState(true);

    const validarBotonEnviar = useCallback (async () => {
      
      if(codigoTipoPersonaCorresponsal.value == "" || tipoIdentificacionCorresponsal.value == "" ||
         identificacionCorresponsal.value == "" || nombreCorresponsal.value == "" || segundoNombreCorresponsal.value == "" ||
         primerApellidoCorresponsal.value == "" || segundoApellidoCorresponsal.value == "" || codigoPais.value == "" ||
         departamentos.value == "" || ciudades.value == "" || direccionCorresponsal.value == "" || telefonoCorresponsal.value == "" ||
         emailCorresponsal.value == "" || preguntasPRQS.value == "" || preguntasPRQS2.value == "" ||
         preguntasPRQS3.value == "" || identificacionAplicaA.value =="" || pqrsAutorizo.value == ""){
     setIsDisabledEnviar(isDisabledEnviar)
    }else{
      setIsDisabledEnviar(!isDisabledEnviar)
    }

      },[]);
      const validarBotonEnviarAutorizo = () => { 
        setIsDisabledEnviar(!isDisabledEnviar);        
      }; 

      
    const handleConsultar = async (e) => {
      let obj = {      
        baseUrl,
        tipoIdentificacionCorresponsal: tipoIdentificacionCorresponsal.value,
        codigoTipoPersonaCorresponsal: codigoTipoPersonaCorresponsal.value,
        identificacionCorresponsal: identificacionCorresponsal.value,
        nombreCorresponsal: nombreCorresponsal.value,
        segundoNombreCorresponsal: segundoNombreCorresponsal.value,
        primerApellidoCorresponsal: primerApellidoCorresponsal.value,
        segundoApellidoCorresponsal: segundoApellidoCorresponsal.value,
        codigoPais: codigoPais.value,
        departamentos: departamentos.value,
        ciudades: ciudades.value,
        direccionCorresponsal: direccionCorresponsal.value,
        telefonoCorresponsal: telefonoCorresponsal.value,
        movilCorresponsal:  telefonoCorresponsal.value, //movilCorresponsal.value,
        emailCorresponsal: emailCorresponsal.value,
        identificacionAplicaA: identificacionAplicaA.value,
        certificadoDirigidoA: certificadoDirigidoA.value,
        pqrsAutorizo: pqrsAutorizo.value,
        preguntasPRQS: preguntasPRQS.value,
        preguntasPRQS2: preguntasPRQS2.value,
        preguntasPRQS3: preguntasPRQS3.value,

      }
      let isValidEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailCorresponsal.value === '') {
        mensaje.onChange({ target: { value: "Por favor ingresa tu correo electrónico. " } })
        showError.onChange({ target: { value: true } });
        showSpinner.onChange({ target: { value: false } });
      } else if (!isValidEmail.test(emailCorresponsal.value)) {
        mensaje.onChange({ target: { value: "Ingresa un correo electrónico válido " } })
        showError.onChange({ target: { value: true } });
        showSpinner.onChange({ target: { value: false } });
      }else{

            showSpinner.onChange({ target: { value: true } });
            const result = await procesarCertificadoSociedadesService (baseUrl,obj);
  
            if (result.response) {
              mensaje.onChange({ target: { value: "No existen datos registrados para la consulta. " } })
              showError.onChange({ target: { value: true } });
              showSpinner.onChange({ target: { value: false } });
            } else {
               let numeroRadicado = { 
                numeroRadicado: result.data.numeroRadicado
              }
              let archivoBase64 = {
                archivoBase64: result.data.archivoBase64
              }
              let tituloCertificado = "Certificación SIGS";
              if(result.data.numeroRadicado != 'Error' && result.data.numeroRadicado != null){
                history.push({
                  pathname: '/respuestaCertificado',
                  state: numeroRadicado, archivoBase64,
                  tituloCertificado
                })
              }else{
                mensaje.onChange({ target: { value: "Ocurrio un error validar con el administrador. " } })
                showError.onChange({ target: { value: true } });
                showSpinner.onChange({ target: { value: false } });
              }
            }
      }
    }

  const buttonCombos = {
    width: "500px",
    height: "40px"
  }

  const titulos = {
    color: "#338DFF;"
  }


  return (
    <div>
  
       <Tooltip anchorSelect=".identificacionCorresponsal" 
          place="top" type="warning">
          Campo Numero documento minimo debe tener 7 caracteres y maximo 15 caracteres
      </Tooltip>
      <Tooltip anchorSelect=".nombreCorresponsal" 
          place="top">
          Campo Razon social minimo debe tener 3 caracteres y maximo 100 caracteres
      </Tooltip>
      <div className="row" >
        <div className="col-lg-10  mx-auto text-left ">
          <button type="button" className="btn-govco fill-btn-govco symbol-btn-govco mixed-btn-govco left-arrow-btn-govco" icon-position="left" style={buttonStyle}  onClick={volver} ><span class="sub-btn-govco"> Volver</span></button>
        </div>
      </div>
      <br></br>
        <div className="row" >
            <div className="col-lg-10  mx-auto">
                <div className="card">
                <span className="card-header text-center" style={titulos} >Datos del solicitante</span>
                </div>

                <div className="panel-body">

                    <div className="row">
                        
                    <div className="form-group col-sm-4 entradas-de-texto-govco" >
                            <label for="tipoIdentificacionCorresponsal"><b className="text-danger">*</b>Tipo documento :</label>
                            <div className="desplegable-govco " style={buttonCombos} id="lista-tipoIdentificacionCorresponsal" data-type="basic">
                                <div className="col-sm-7">
                                <Select className=" basic-single"  
                                    placeholder="Selecciona un tipo doc.."
                                    classNamePrefix="select"
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionstipoIdentificacionCorresponsal.value}  onChange={seleccionoTipoIdentificacionCorresponsal} />
                                </div>
                            </div>
                            <br></br>
                        </div>

                        <div className="form-group col-sm-4 entradas-de-texto-govco" >
                            <label for="codigoTipoPersonaCorresponsal"><b className="text-danger">*</b>Tipo persona :</label>
                            <div className="desplegable-govco" style={buttonCombos} id="lista-tipoIdentificacionCorresponsal" data-type="basic">
                                <div className="col-sm-7">
                                <Select className="basic-single"  
                                    placeholder="Selecciona un tipo pers.."
                                    classNamePrefix="select"
                                    getOptionValue={option => option['tipoIdentificacion']}
                                    getOptionLabel={option => option['descripcion']}
                                    options={optionsCodigoTipoPersonaCorresponsal.value}  onChange={seleccionoCodigoTipoPersonaCorresponsal} />
                                </div>
                            </div>
                        </div>
                        

                        <div className="form-group col-sm-4 entradas-de-texto-govco">                        
                         <label for="identificacionCorresponsal"><b className="text-danger">*</b>N&uacute;mero documento :</label>
                          <input type="text" id="identificacionCorresponsal" className="identificacionCorresponsal form-control input-govco "
                            placeholder="N&uacute;mero identificaci&oacute;n" 
                            minlength="7" 
                            maxlength="15" 
                            pattern="/[A-Z a-z]/"
                            required = "true" 
                            {...identificacionCorresponsal}/>
                        </div>

                    </div>

                    <div class="row" >

                        <div class="form-group col-sm-12 entradas-de-texto-govco" >
                            <label for="nombreCorresponsal"><b className="text-danger">*</b>Nombre o Raz&oacute;n social :</label>
                            <input type="text" className="nombreCorresponsal form-control input-govco" placeholder="Raz&oacute;n social"
                                id="nombreCorresponsal"
                                name="nombreCorresponsal"
                                minlength="3"
                                maxlength="100"
                                pattern="/[A-Z a-z]/"
                                trim="true"
                                change="configurarNombre()"
                                required="true"
                                disabled={isDisabledRazonSocial}
                                {...nombreCorresponsal}/>
                        </div>
                    </div>
                    
                   <div class="row">

                            <div className="form-group col-sm-3 entradas-de-texto-govco" >
                                <label for="primerNombreCorresponsal"><b className="text-danger">*</b>Primer nombre :</label>
                                <input type="text" className="form-control input-govco" placeholder="Primer Nombre"
                                    id="primerNombreCorresponsal"
                                    name="primerNombreCorresponsal"
                                    minlength="3"
                                    maxlength="15"
                                    pattern="/[A-Z a-z]/"
                                    trim="true"
                                    required="true"
                                    disabled={isDisabledNombres}
                                    {...primerNombreCorresponsal}/>
                            </div>

                            <div className="form-group col-sm-3 entradas-de-texto-govco" >
                                <label for="segundoNombreCorresponsal">Segundo nombre :</label>
                                <input type="text" className="form-control input-govco" placeholder="Segundo Nombre"
                                    id="segundoNombreCorresponsal"
                                    name="segundoNombreCorresponsal"
                                    minlength="3"
                                    maxlength="15"
                                    pattern="/[A-Z a-z]/"
                                    trim="true"
                                    required="false"
                                    disabled={isDisabledNombres}
                                    {...segundoNombreCorresponsal}/>
                            </div>

                            <div className="form-group col-sm-3 entradas-de-texto-govco" ng-class="{'has-error': !formulario.primerApellidoCorresponsal.$valid}">
                                <label for="primerApellidoCorresponsal"><b className="text-danger">*</b>Primer apellido :</label>
                                <input type="text" className="form-control input-govco" placeholder="Primer Apellido"
                                    id="primerApellidoCorresponsal"
                                    name="primerApellidoCorresponsal"
                                    minlength="3"
                                    maxlength="15"
                                    pattern="/[A-Z a-z]/"
                                    trim="true"
                                    required="true"
                                    disabled={isDisabledNombres}
                                    {...primerApellidoCorresponsal}/>
                            </div>

                            <div className="form-group col-sm-3 entradas-de-texto-govco" >
                                <label for="segundoApellidoCorrespoonsal">Segundo apellido :</label>
                                <input type="text" className="form-control input-govco" placeholder="Segundo Apellido"
                                    id="segundoApellidoCorresponsal"
                                    name="segundoApellidoCorrespoonsal"
                                    minlength="3"
                                    maxlength="15"
                                    pattern="/A-Z a-z]/"
                                    ntrim="true"
                                    ng-required="false"
                                    disabled={isDisabledNombres}
                                    {...segundoApellidoCorresponsal}/>
                            </div>

                        </div>

                    <div class="row">

                        <div className="form-group col-sm-4 entradas-de-texto-govco" >
                            <label for="codigoPais"><b className="text-danger">*</b>Pa&iacute;s :</label>
                            <input type="hidden" id="nombrePais" name="nombrePais" ng-value="nombrePais"/>
                            <div className="desplegable-govco" style={buttonCombos} id="lista-codigoPais" data-type="basic">
                                <div className="col-sm-8">
                                <Select className=" basic-single"  
                                    placeholder="Selecciona un país.."
                                    classNamePrefix="select"
                                    getOptionValue={option => option['idPais']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionscodigoPais.value} 
                                    defaultValue={{ value: '80', label: 'COLOMBIA' }}
                                    onChange={seleccionocodigoPais} />
                                </div>
                            </div>
                            <br></br>
                        </div>

                        <div className="form-group col-sm-4 entradas-de-texto-govco" >
                            <label for="codigoDepartamento"><b className="text-danger">*</b>Departamento :</label>
                            <input type="hidden" id="nombreDepartamento" name="nombreDepartamento" ng-value="nombreDepartamento"/>
                            <div className="desplegable-govco" style={buttonCombos} id="lista-depaetamentos" data-type="basic">
                                <div className="col-sm-8">
                                <Select className="  basic-single"  
                                   placeholder="Selecciona un departamento.."
                                   classNamePrefix="select"
                                    getOptionValue={option => option['idDepartamento']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionsDepartamentos.value}  onChange={seleccionoDepartamentos} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-sm-4 entradas-de-texto-govco" >
                            <label for="codigoCiudad"><b className="text-danger">*</b>Ciudad :</label>
                            <input type="hidden" id="nombreCiudad" name="nombreCiudad" ng-value="nombreCiudad"/>
                            <div className="desplegable-govco" style={buttonCombos} id="lista-ciudades" data-type="basic">
                                <div className="col-sm-8">
                                <Select className="  basic-single"  
                                    classNamePrefix="select"
                                    placeholder="Selecciona una ciudad.."
                                    getOptionValue={option => option['idCiudad']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionsCiudades.value}  onChange={seleccionoCiudades} />
                                </div>
                            </div>
                        </div>

                        
                    </div>
                    
                    <div className="row">

                      <div className="form-group col-sm-4 entradas-de-texto-govco"  >
                          <label for="direccionCorresponsal"><b class="text-danger">*</b>Direcci&oacute;n :</label>
                          <input type="text" className="form-control input-govco" placeholder="Direcci&oacute;n"
                              id="direccionCorresponsal"
                              name="direccionCorresponsal"
                              minlength="5"
                              maxlength="50"
                              trim="true"
                              required="true"
                              disabled="true"
                              {...direccionCorresponsal}/>
                      </div>
                      <div className="col-md-2 mt-5">
                        <button type="button" className="govco-angle-double-up"  style={buttonStyle}  onClick={handleOpenDireccion} >+</button>
                      </div>


                      <div className="form-group col-sm-4 entradas-de-texto-govco"  >
                          <label for="telefonoCorresponsal"><b className="text-danger">*</b>Tel&eacute;fono :</label>
                          <input type="number" className="form-control input-govco" placeholder="Tel&eacute;fono"
                              id="telefonoCorresponsal"
                              name="telefonoCorresponsal"
                              minlength="7"
                              maxlength="10"
                              pattern="/[0-9]/"
                              trim="true"
                              required="true"
                              {...telefonoCorresponsal}/>
                      </div>

                      </div>
                    
                    <div className="row">
                        <div className="form-group col-sm-12 entradas-de-texto-govco" >
                        <label for="emailCorresponsal"><b ng-if="!pqrsAnonimo" className="text-danger">*</b>Correo electr&oacute;nico :</label>
                            <input type="email" class="form-control input-govco" placeholder="Correo Electr&oacute;nico"
                                id="emailCorresponsal"
                                name="emailCorresponsal"
                                minlength="7"
                                trim="true"
                                required="true"
                                {...emailCorresponsal}/>
                        </div>
                    </div>

                    
                       <div class="row">
                          <div className="form-group col-sm-4 entradas-de-texto-govco"  >
                            <label for="codigoPregunta1"><b className="text-danger">*</b>Seleccione el grupo de inter&eacute;s al que pertenece:</label>
                            <input type="hidden" id="nombrePregunta1" name="nombrePregunta1" ng-value="nombrePregunta1"/>
                            <div className="desplegable-govco" style={buttonCombos} id="lista-ciudades" data-type="basic">
                                <div className="col-sm-7">
                                <Select className=" basic-single" 
                                 placeholder="Selecciona un grupo.." 
                                    classNamePrefix="select"
                                    getOptionValue={option => option['codigo']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionsListarPreguntasPQRS.value}  onChange={seleccionoPreguntasPQRS} />
                                </div>
                            </div>
                          </div>
                          <div className="form-group col-sm-4 entradas-de-texto-govco"  >
                            <label for="codigoPregunta2"><b className="text-danger">*</b>Genero:</label>
                            <input type="hidden" id="nombrePregunta2" name="nombrePregunta2" ng-value="nombrePregunta2"/>
                            <div className="desplegable-govco" style={buttonCombos} id="codigoPregunta2" data-type="basic">
                                <div className="col-sm-7">
                                <Select className=" basic-single" 
                                 placeholder="Selecciona un genero.." 
                                    classNamePrefix="select"
                                    getOptionValue={option => option['codigo']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionsListarPreguntasPQRS2.value}  onChange={seleccionoPreguntasPQRS2} />
                                </div>
                            </div>
                          </div>
                          <div className="form-group col-sm-4 entradas-de-texto-govco"  >
                            <label for="codigoPregunta3"><b className="text-danger">*</b>Pertenece a alguno de los siguientes grupos poblaciones :</label>
                            <input type="hidden" id="nombrePregunta3" name="nombrePregunta3" ng-value="nombrePregunta3"/>
                            <div className="desplegable-govco" style={buttonCombos} id="codigoPregunta3" data-type="basic">
                                <div className="col-sm-7">
                                <Select className=" basic-single" 
                                 placeholder="Selecciona un grupo.." 
                                    classNamePrefix="select"
                                    getOptionValue={option => option['codigo']}
                                    getOptionLabel={option => option['nombre']}
                                    options={optionsListarPreguntasPQRS3.value}  onChange={seleccionoPreguntasPQRS3} />
                                </div>
                            </div>
                          </div>
                        </div>
                </div>

            </div>
        </div>

        <div className="row" >
            <div className="col-lg-10  mx-auto">
            <br></br>
                <div className="card">
                <span className="card-header text-center" style={titulos} >Sociedad a Consultar</span>
                </div>

                <div className="panel-body">

                    <div className="row">
                        <div className="form-group col-sm-6 entradas-de-texto-govco" >
                            <label for="identificacionAplicaA"><b class="text-danger">*</b>N&uacute;mero documento (Sin d&iacute;gito de verificaci&oacute;n) :</label>
                            <input type="number" className="form-control input-govco" placeholder="N&uacute;mero identificaci&oacute;n"
                                id="identificacionAplicaA"
                                name="identificacionAplicaA"
                                model="identificacionAplicaA"
                                minlength="7"
                                pattern="/[0-9]/"
                                trim="true"
                                required="true"
                                {...identificacionAplicaA}/>
                        </div>
                        
                        
                        <div className="form-group col-sm-6 entradas-de-texto-govco" >
                            <label for="certificadoDirigidoA"><b className="text-danger">*</b>Dirigido a :</label>
                            <input type="text" className="form-control input-govco" placeholder="Dirigido a"
                                id="certificadoDirigidoA"
                                name="certificadoDirigidoA"
                                minlength="5"
                                maxlength="100"
                                trim="true"
                                required="true"
                                {...certificadoDirigidoA}/>
                        </div>

                    </div>
                    
                    <div className="row" id="DivAutorizo">
                      <div className="form-group col-sm-12">
                      <p align="justify">
                        <b> Autorizo a la Superintendencia de Sociedades para dar
                          tratamiento de mis datos personales registrados en el
                          presente formulario, conforme a la Ley 1581 de 2012 y la
                          pol&iacute;tica de tratamiento de datos personales, con la
                          finalidad de otorgar una respuesta efectiva a su
                          petici&oacute;n, queja, reclamo, sugerencia y denuncia,
                          realizar encuestas de satisfacci&oacute;n y percepci&oacute;n
                          sobre la entidad y la realizaci&oacute;n de la
                          caracterizaci&oacute;n de usuarios con el objetivo de
                          focalizar estrategias de mejora en la atenci&oacute;n de las
                          solicitudes. 
                          <br></br><a href="https://www.supersociedades.gov.co/ley-de-proteccion-de-datos" target="_blank">Pol&iacute;tica de tratamiento de datos personales</a>
                          <br></br>&nbsp;&nbsp;
                          <table>
                          <tr>
                            <td><input type="checkbox" id="pqrsAutorizo"
                              name="pqrsAutorizo" value="1"
                              onClick={validarBotonEnviarAutorizo}
                              {...pqrsAutorizo}/>
                            </td>
                            <td>&nbsp;Autorizo</td>
                          </tr>
                          </table>
                        </b>
                      </p>
                      </div>            
					          </div>

                </div>

            </div>
        </div>

          <div className="row" >
              <div className="col-lg-10  mx-auto">
                  <div className="card alert alert-info">
                      <div className="text-right " >
                        <button type="button" className="btn-govco fill-btn-govco symbol-btn-govco mixed-btn-govco left-arrow-btn-govco pull-right"  
                        style={buttonStyle}  
                        disabled={isDisabledEnviar}
                        onClick={handleConsultar} > Enviar</button>
                      </div>
                  </div>
              </div>
          </div>        

          <Modal show={showError.value} onHide={handleCloseError}>

            <Modal.Body><div className="modal-body modal-body-govco center-elements-govco">
              <div className="modal-icon">
                <span className="modal-icon-govco modal-error-icon"></span>
              </div>
              <h3 className="modal-title-govco error-govco">
                Error del Sistema
              </h3>
              <p className="modal-text-govco modal-text-center-govco">
                {mensaje.value}
              </p>
            </div></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="btn btn-primary btn-modal-govco" onClick={handleCloseError}>
                Cerrar
              </Button>
            </Modal.Footer>
            </Modal>

            <Modal size="sm" show={showSpinner.value} className="transparencia" aria-labelledby="example-modal-sizes-title-sm"  >

              <Modal.Body style={{ background: "transparent" }}>
                <div className="spinner-indicador-de-carga-govco" style={spinnerStyle} role="status" aria-hidden="true">
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </Modal.Body>
              </Modal>

          <Modal show={showDireccion.value} onHide={handleCloseDireccion} >
            <Modal.Body><div className="modal-body modal-body-govco center-elements-govco">
              <h3>Direcci&oacute;n</h3>
                <div className="form-group col-sm-12 entradas-de-texto-govco"  >
                                    <label for="direccionCorresponsal"><b className="text-danger">*</b>Direcci&oacute;n :</label>
                                    <input type="text" className="form-control input-govco" placeholder="Direcci&oacute;n"
                                        id="direccionCorresponsal"
                                        name="direccionCorresponsal"
                                        minlength="5"
                                        maxlength="50"
                                        trim="true"
                                        required="true"
                                        {...direccionCorresponsal}/>
                  </div>

                <div className='row'>
                  <div className='col-lg-6'>
                    <Button variant="secondary" className="btn btn-primary btn-modal-govco" onClick={handleCloseDireccion}>Guardar</Button>
                  </div>

                  <div className='col-lg-6'>
                    <Button variant="secondary" className="btn btn-primary btn-modal-govco" onClick={handleCloseDireccion}>Cancelar</Button>
                  </div>
                </div>  

            </div>
            </Modal.Body>
          </Modal>


    </div>
  );



}

// custom hook to manage the form input
const useFormInput = initialValue => {

  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}


const useFormMap = () => {

  const [value, setValue] = useState([]);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default CertificadoCamaras;