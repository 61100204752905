import axios from "axios";

// set token to the axios
export const setAuthToken = (token, usuario, ipUsuario) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['usuario'] = usuario;
    axios.defaults.headers.common['aplicacion'] = "envioCorreoMasivo2.0Front";
    axios.defaults.headers.common['ipUsuario'] = ipUsuario;
  }
  else {
    delete axios.defaults.headers.common['Authorization'];
  }
}


// get list of the users
export const sendEmail = async (asunto, cuerpo, destinatarios, archivo, archivosAjuntos, nombresAjuntos, url) => {
  try {
    let body = {
      asunto: asunto,
      cuerpo: cuerpo,
      destinatarios: destinatarios,
      archivoDestinos: archivo,
      archivosAjuntos: archivosAjuntos,
      nombresAjuntos: nombresAjuntos
    };

    return await axios.post(`${url}/correoMasivo/enviar`, body);
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}
