import React, { useEffect, useState, Component, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "../services/consulta.js";
import { fileLoadConfigAsync } from "../asyncActions/configAsyncActions.js";
import { useHistory } from "react-router-dom";

//Hojas de estilos
import "../govco/src/form/carga-de-archivo.css";
import "../govco/src/transversal/barra-superior.css";
import "../govco/src/transversal/pie-de-pagina.css";
import "../govco/src/form/entradas-de-texto.css";
import "../govco/src/general/botones.css";
import "../govco/src/general/alerta-modal.css";
import "../govco/src/general/tablas.css";
import "../govco/src/general/indicador-de-carga.css";
import "../govco/src/transversal/barra-accesibilidad.css";
import "../govco/src/general/etiqueta.css";
import "../govco/src/general/tables.css";
import * as entrada from "../govco/src/form/entradas-de-texto.js";
import * as ip from "../lib/GetIP.js";
import TablaTramites from "../components/TramitesTable.js";
import "../govco/src/form/button_app.css";
/** Imports APP DocumentosAdicionales */
import { obtenerTramitesPermitidos } from "../services/consulta";

function FormularioAdministrador() {
  const history = useHistory();

  const dispatch = useDispatch();
  const authObj = useSelector((state) => state.auth);
  const { access_token, expires_in, userName, baseUrl } = authObj;
  const showError = useFormInput(false);
  const mensaje = useFormInput(false);   
  const optionsTramitesPermitidos = useFormInput([]);

  const mthObtenerTramitesPermitidos = useCallback(async (event) => {
    const result = await obtenerTramitesPermitidos(baseUrl);
    // alert(JSON.stringify(result.data.objeto));
    if (result.response) {
      mensaje.onChange({
        target: {
          value:
            "Ha ocurrido un error en el servicio, por favor intenta mas tarde.",
        },
      });
      showError.onChange({ target: { value: true } });
    } else {
      optionsTramitesPermitidos.onChange({target: { value: result.data.objeto },});
    }
  }, []);

  
 useEffect(() => {
    mthObtenerTramitesPermitidos();
    entrada.initInput();
    ip.getIPs();
    dispatch(fileLoadConfigAsync());
    const verifyTokenTimer = setTimeout(() => {
      if (sessionStorage.getItem("ip") != null) {
        clearTimeout(verifyTokenTimer);
        setAuthToken(access_token, userName, sessionStorage.getItem("ip"));
      }
    }, 1000);
    return () => {
      clearTimeout(verifyTokenTimer);
    };
  }, []);  
  
  function volver() {
    history.goBack();
  }

  return (
    <div>      
      <div className="row">
        <div className="col-lg-10  mx-auto text-left ">
          <br/>
          <TablaTramites datos={optionsTramitesPermitidos.value} />
        </div>
        <br/>
        <br/>
        <br/>
        
        <div className="col-lg-10  mx-auto text-left ">
          <button type="button"
                  icon-position="left" class="btn-govco fill-btn-govco" onClick={volver}><span class="sub-btn-govco">Volver</span>
                </button>                              
        </div>
      </div>
    </div>
  );
}

// custom hook to manage the form input
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

const useFormMap = () => {
  const [value, setValue] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default FormularioAdministrador;
