import axios from "axios";

// set token to the axios
export const setAuthToken = (token, usuario, ipUsuario) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["usuario"] = usuario;
    axios.defaults.headers.common["aplicacion"] = "DocumentosAdicionales2.0";
    axios.defaults.headers.common["ipUsuario"] = ipUsuario;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const autenticarSociedadService = async (
  numeroDocumento,
  claveWeb,
  url
) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/autenticarSociedad/${numeroDocumento},${claveWeb}`,
      null
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const obtenerTiposIdentificacionService = async (url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerTiposIdentificacion`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const listarTiposPersonaService = async (tipoPersona, url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/listarTiposPersona/${tipoPersona}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const obtenerPaisesNacionalidadService = async (url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerPaisesNacionalidad`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const obtenerDepartamentosService = async (idPais, url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerDepartamentos/${idPais}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const obtenerCiudadesService = async (idPais, idDepartamento, url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerCiudades/${idPais}/${idDepartamento}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const listarPreguntasPQRSPorTipoService = async (tipoPregunta, url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/listarPreguntasPQRSPorTipo/${tipoPregunta}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const obtenerCamarasComercioSAIRService = async (url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerCamarasComercioSAIR`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const consultarPersonaNaturalJuridicaPorNits = async (
  url,
  identificacionCorresponsal
) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/consultarPersonaNaturalJuridicaPorNits/${identificacionCorresponsal}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const procesarCertificadoCamaraService = async (url, obj) => {
  try {
    //alert(JSON.stringify(obj));
    //let obj = {nitPersonalizada:nitPersonalizada, estado:estado, situacion:situacion, departamento:departamento, orden:orden}
    return await axios.post(
      `${url}/documentosAdicionales/procesarCertificadoCamara`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const procesarCertificadoSociedadesService = async (url, obj) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/procesarCertificadoSociedad`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const descargarCertificadoService = async (url, obj) => {
  try {
    alert(JSON.stringify(obj));
    return await axios.post(
      `${url}/documentosAdicionales/descargarCertificado`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 * API /documentosAdicionales/obtenerTramitesPermitidos
 * -- capacidad que permite obtener los tramites que son permitidos cargar
 * @param {*} url 
 * @returns 
 */
export const obtenerTramitesPermitidos = async (url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerTramitesPermitidos`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 * API /obtenerParametroPorTipoParametro/{tipoParametro}
 * -- capacidad que permite obtener los parametros por código
 * @param {*} url 
 * @returns 
 */
export const obtenerParametrosPorCodigo = async (tipoParametro,url) => {
  try {
    return await axios.get(
      `${url}/documentosAdicionales/obtenerParametroPorTipoParametro/${tipoParametro}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/actualizarTramitePermitido
 *  -- capacidad que permite actualizar los datos de un trámite espesífico
 */
export const actualizarTramitePermitidoService = async (url, obj) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/actualizarTramitePermitido`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/actualizarTramitePermitido
 *  -- capacidad que permite adicionar un nuevo trámite
 */
export const agregarTramitePermitidoService = async (url, obj) => {
  try {
    return await axios.post(
      `${url}/documentosAdicionales/agregarTramitePermitido`,
      obj
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

/**
 *  API /documentosAdicionales/consultarDocumentoAdicionalActivos
 *  -- consultar documentos adicionales activos
 */
export const obtenerDocumentoAdicionalActivos = async (url, obj) => {
  try {
    const response = await axios.post(`${url}/documentosAdicionales/consultarDocumentoAdicionalActivos`, obj);
    return response.data;
  } catch (err) {
    // Captura errores con más detalle
    return {
      error: true,
      response: err.response ? err.response.data : null,
      message: err.message,
    };
  }
};

/**
 *  API /documentosAdicionales/consultarDocumentoAdicionalActivos
 *  -- radicar documentos adicionales activos faltantes
 */
export const radicarDocumentoAdicionalActivos = async (url,numeroRadicado,anio,numeroIdentificacion,codigoTramite,role,obj) => {
  try {
    const response = await axios.post(`${url}/documentosAdicionales/radicarTramite/`+numeroRadicado+`/`+anio+`/`+numeroIdentificacion+`/`+codigoTramite+`/`+role+``, obj);
    return response.data;
  } catch (err) {
    // Captura errores con más detalle
    return {
      error: true,
      response: err.response ? err.response.data : null,
      message: err.message,
    };
  }
};