import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from "../actions/authActions.js";
import { setAuthToken } from '../services/email.js';
import { sendEmail } from '../services/email.js';
import { fileLoadConfigAsync } from '../asyncActions/configAsyncActions.js';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

//govco
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import ContentEditable from 'react-contenteditable'
//Hojas de estilos
import "../govco/src/form/carga-de-archivo.css"
import "../govco/src/transversal/barra-superior.css"
import "../govco/src/transversal/pie-de-pagina.css"
import "../govco/src/form/entradas-de-texto.css";
import "../govco/src/general/botones.css"
import "../govco/src/general/alerta-modal.css";
import "../govco/src/general/tablas.css"
import "../govco/src/general/indicador-de-carga.css"
import "../govco/src/transversal/barra-accesibilidad.css";
import "../govco/src/general/etiqueta.css"
//Librerias
import * as entrada from "../govco/src/form/entradas-de-texto.js"
import * as ip from "../lib/GetIP.js"
//Componentes
import { descargarCertificadoService } from '../services/consulta.js';
import Modal from 'react-bootstrap/Modal';
import TablaArchivo from '../components/tablaArchivo.js';
import moment from 'moment';

function RespuestaCertificado() {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authObj = useSelector(state => state.auth);
  const { access_token, expires_in, userName, baseUrl } = authObj;
  const showError = useFormInput(false);
  const mensaje = useFormInput(false);
  const showSucess = useFormInput(false);
  const showSpinner = useFormInput(false);

  useEffect(() => {
    entrada.initInput();
    ip.getIPs();
    dispatch(fileLoadConfigAsync());
    const verifyTokenTimer = setTimeout(() => {
      if (sessionStorage.getItem('ip') != null) {        
        clearTimeout(verifyTokenTimer);
        setAuthToken(access_token, userName, sessionStorage.getItem('ip'));        
      }

    }, 1000);
    return () => {
      clearTimeout(verifyTokenTimer);
    }
  }, []);




  const contentEditable = React.createRef();


  const buttonStyle = {
    width: "1650px",
    height: "42px"
  }

  const spinnerStyle = {
    width: "90px",
    height: "90px",
    borderWidth: "8px"
  }

  const modalStyle = {
    position: "absolute",
    top: "40%",
    left: "10%",
    marginTop: "20%",
    marginLeft: "-50px"
  }


  function volver(){
   // history.goBack();
   history.push({
    pathname: '/dashboard'
  })
  }

   const handledescargar = async (e) => {

         // alert(location.archivoBase64.archivoBase64);
          let pdf = location.archivoBase64.archivoBase64.replace('"','');
      //    const container = document.querySelector('#container');
          const tempLink = document.createElement('a');
          tempLink.href = `data:application/pdf;base64,${pdf.replace('"','')}`;
          tempLink.setAttribute('download', 'certificado_'+location.state.numeroRadicado+'.pdf');
          tempLink.click();

  }

  return (
    <div>


    <div className="row" >
        <div className="col-lg-10  mx-auto text-left">
          <button type="button" className="btn-govco fill-btn-govco symbol-btn-govco mixed-btn-govco left-arrow-btn-govco" icon-position="left" style={buttonStyle}  onClick={volver} ><span class="sub-btn-govco"> Volver</span></button>
        </div>
    </div>
  <br></br>
<div className="row" >
        <div className="col-lg-11  mx-auto">
          <div className="card ">
            <div className="card-header text-center">
              <b>{location.tituloCertificado}</b>
            </div>

              <div className="row">
                <div className="card-body d-flex justify-content-center">

                <div className="panel-body">
                      <div className="row" ng-hide="ocultarInformacion">
                        <div className="form-group col-sm-12">
                              Certificación n&uacute;mero <b>
                            <a href={handledescargar}>{location.state.numeroRadicado}</a>
                            </b> expedida exitosamente.
                            <br/><br/>      
                        </div>
                        <div className="col-lg-4  mx-auto">
                            <button type="submit" className="btn btn-primary" onClick={handledescargar}>Descargar Certificado</button>
                        </div>
                      </div>
                  </div>
                
                </div>
              </div>

          </div>
        </div>
      </div>




    </div>
  );



}

// custom hook to manage the form input
const useFormInput = initialValue => {

  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}


const useFormMap = () => {

  const [value, setValue] = useState([]);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default RespuestaCertificado;