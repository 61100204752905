import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import CertificadoCamaras from './pages/CertificadoCamaras';
import CertificadoSociedades from './pages/CertificadoSociedades';
import FormularioAdministrador from './pages/FormularioAdministrador';
import FormularioRadicacion from './pages/FormularioRadicacion';
import RespuestaCertificado from './pages/RespuestaCertificado';

import "./govco/src/transversal/pie-de-pagina.css"
import "./govco/src/transversal/volver-arriba.css"
import FooterGovCo from './components/footer';
import HeaderGovCo from './components/header';
import "./govco/src/transversal/barra-accesibilidad.css";
import BarraAccesibilidadGovCo from './components/barraAccesibilidad';
import SubirArchivoGovCo from './components/subirArriba';
import { verifyUserSuccess, } from "../src/actions/authActions";
import { Form } from 'react-bootstrap';
import AccordionMenu from './components/AccordionMenu';

function App() {

  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  const setItems = () => {
    const items = [];
    if(JSON.parse(sessionStorage.getItem("usuario"))){
      if(getAuthorities(verifyUserSuccess(JSON.parse(sessionStorage.getItem("usuario"))).payload.access_token) == 'ROLE_INTERNAL'){
        items.push({ title: "Administrador", content: "Contenido para el Administrador del sistema ",path:"/formularioAdministrador" });
      }else{
        items.push({ title: "Radicacion", content: "Contenido para los usuarios",path:"/formularioRadicacion" });
      }
    }
    return items;
  }

  const decodeJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log('jsonPayload',JSON.parse(jsonPayload))
    return JSON.parse(jsonPayload);
  }

  const getAuthorities = (token) => {
    const { authorities } =  decodeJWT(token);
    return authorities[0];
  }
  

  const { authLoading, isAuthenticated } = authObj;

  // verify token on app load
  useEffect(() => {
    //  dispatch(verifyTokenAsync());
    if (JSON.parse(sessionStorage.getItem('estaAutenticado'))) {
      let obj = JSON.parse(sessionStorage.getItem("usuario"));
      dispatch(verifyUserSuccess(obj));
    }

  }, []);

return (
    <div className="App">
      <BrowserRouter basename='/DocumentosAdicionales'>
        <div>
          <div>
            <HeaderGovCo />
            <BarraAccesibilidadGovCo />
            <SubirArchivoGovCo />          
            <AccordionMenu items={setItems()} isAuthenticated={isAuthenticated}></AccordionMenu>            
          </div>
          <div className="content" id="para-mirar" style={{ minHeight: "80vh", marginTop: "100px" }}>
            <Switch>
              <PublicRoute path="/login" component={Login} isAuthenticated={isAuthenticated} />
              <PrivateRoute path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} />
              {/* <PrivateRoute path="/certificadoCamaras" component={CertificadoCamaras} isAuthenticated={isAuthenticated} /> */}
              {/* <PrivateRoute path="/certificadoSociedades" component={CertificadoSociedades} isAuthenticated={isAuthenticated} /> */}
              <PrivateRoute path="/FormularioRadicacion" component={FormularioRadicacion} isAuthenticated={isAuthenticated} />
              <PrivateRoute path="/formularioAdministrador" component={FormularioAdministrador} isAuthenticated={isAuthenticated} />
              {/* <PrivateRoute path="/respuestaCertificado" component={RespuestaCertificado} isAuthenticated={isAuthenticated} /> */}
              <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
            </Switch>
          </div>
          <FooterGovCo />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
