import {
  LOAD_FILE_CONFIG
} from "./actionTypes";




export const fileLoadConfig = ({ baseUrl,llaveCAPTCHA }) => {

  return {
    type: LOAD_FILE_CONFIG,
    payload: {
      baseUrl,
      llaveCAPTCHA
    }
  }
}