import React, { useState } from "react";
import "../govco/src/form/accordion.css";
import {  useHistory } from "react-router-dom";

const AccordionMenu = ({items,isAuthenticated}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const history = useHistory();
    const handleClick = (index,item) => {
    setActiveIndex(activeIndex === index ? null : index);
    let obj = {
    };
    history.push({
        pathname: item.path,
        state: obj,
      });
  };
  return (
    <div className={`tabprincipal ${isAuthenticated ? "" : "active"}`}>
      <div style={{"backgroundColor":"blue"}}>
        {items.map((item, index) => (
            <div key={index} className="accordion-item">
              <div className={`accordion-title ${activeIndex === index ? "active" : ""}`} onClick={() => handleClick(index,item)}>
                {item.title}              
              </div>              
            </div>
          ))}          
      </div>
    </div>
  );
};

export default AccordionMenu;
