import axios from "axios";


axios.defaults.withCredentials = true;

// set token to the axios
export const setAuthToken = (token, usuario, ipUsuario) => {

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['usuario'] = usuario;
    axios.defaults.headers.common['aplicacion'] = "DocumentosAdicionales2.0";
    axios.defaults.headers.common['ipUsuario'] = ipUsuario;
  }
  else {
    delete axios.defaults.headers.common['Authorization'];
  }
}


// user login API to validate the credential
export const userLoginService = async (username, password, url) => {
  try {
    let body = new URLSearchParams({
      'username': btoa(username),
      'password': btoa(password),
      'grant_type': 'password'
    });
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa("frontDocumentosAdicionales:Fr0nt.d0c1m2n3t4s.0d1c23n4l5s")
      }
    }    
    return await axios.post(`${url}/oauth/token`, body, config);
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}
