import {
  USER_LOGIN_STARTED, USER_LOGIN_FAILURE,
  VERIFY_USER_SUCCESS, USER_LOGOUT, LOAD_FILE_CONFIG
} from "../actions/actionTypes";

// define initial state of auth reducer
const initialState = {
  access_token: null, // manage the access token
  expires_in: null, // manage expiry time of the access token
  userName: null, // manage the user details
  authLoading: true, // to indicate that the auth API is in progress
  isAuthenticated: false, // consider as a authentication flag
  userLoginLoading: false, // to indicate that the user signin API is in progress
  loginError: null, // manage the error of the user signin API
  baseUrl: window.configs.baseUrl,
  llaveCAPTCHA: window.configs.llaveCAPTCHA
}

// update store based on type and payload and return the state
const auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      return {
        ...state,
        userLoginLoading: true
      };
    // user login - ended/failed
    case USER_LOGIN_FAILURE:
      const { error } = action.payload;
      return {
        ...state,
        loginError: error,
        userLoginLoading: false
      };
    // verify token - success
    case VERIFY_USER_SUCCESS:
      const { access_token, expires_in, nombreCompleto, userName } = action.payload;
      return {
        ...state,
        access_token,
        expires_in,
        nombreCompleto,
        userName,
        isAuthenticated: true,
        authLoading: false,
        userLoginLoading: false
      }
    // handle user logout
    case USER_LOGOUT:
      return {
        ...initialState,
        authLoading: false
      }
    case LOAD_FILE_CONFIG:
      const { baseUrl,llaveCAPTCHA } = action.payload;
      return {
        ...state,
        baseUrl,
        llaveCAPTCHA,
        authLoading: false,
        userLoginLoading: false
      }


    default:
      return state
  }
}

export default auth;